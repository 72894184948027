/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Tondiki's Portfolio",
  description:
    'My name is Tondiki Sianipar I am a web developer',
  og: {
    title: "Tondiki Sianipar Portfolio",
    type: "website",
    url: "https://dhikisianipar.com/",
  },
};

//Home Page
const greeting = {
  title: "Tondiki Sianipar",
  logo_name: "TondikiSianipar",
  nickname: "dhiki",
  subTitle:
    `I am a fast learner and a challenger interested in web development and app development.`,
  resumeLink:
    "https://drive.google.com/file/d/1EGVLJYg5Hu-9C_59reKcVx5DZuCAl0pj/view?usp=sharing",
};

const socialMediaLinks = [
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/dhikisianipar/",
    fontAwesomeIcon: "fab fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Mail",
    link: "mailto:mail@dhikisianipar.com",
    fontAwesomeIcon: "fa fa-envelope", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/dhikisianipar/",
    fontAwesomeIcon: "fab fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  }
];

const skills = {
  data: [
    {
      title: "Fullstack Developer",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building websites using PHP, PostgreSQL & Bootstrap",
        "⚡ Creating application backend in Node",
        "⚡ IT Administrator",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Gatsby",
          fontAwesomeClassname: "simple-icons:gatsby",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    }
  ],
};

// Experience Page
const experience = {
  title: "Experience & Projects",
  subtitle: "Work & Projects",
  description:
    `After graduating in 2015, I helped found Inbuzz, a Social Media Advertising Platform. In 2016, I freelanced from home to take care of my parents. I returned to the professional scene in 2017, making web apps that boost productivity in Telkom Indonesia until now.`,
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Fullstack Developer",
          company: "PT Infomedia Solusi Humanika (ISH)",
          company_url: "https://ish.co.id/",
          logo_path: "infomedia.jpg",
          duration: "August 2017 - PRESENT",
          location: "Central Jakarta",
          description:
            "Working as Full Stack Developer building a website, database maintenance and server maintenance.",
          color: "#0879bf",
        }
      ],
    },
    {
      title: "Projects",
      experiences: [
        {
          title: "Windi",
          company: "Telkom Indonesia Project",
          company_url: "",
          logo_path: "windi.png",
          duration: "May 2021 - PRESENT",
          location: "",
          description:
            "Windi is a dashboard to monitor Telkom's progress in winning its competition in focus areas, providing an in-depth view to both marketing and infrastructural aspects.",
          color: "#0879bf",
        },
        {
          title: "ADA",
          company: "Telkom Indonesia Project",
          company_url: "",
          logo_path: "ada.png",
          duration: "February 2022 - PRESENT",
          location: "",
          description:
            "ADA is a platform to track the activities of technicians & sales forces within the Greater Jakarta Area.",
          color: "#0879bf",
        },
        {
          title: "Jakcx",
          company: "Telkom Indonesia Project",
          company_url: "",
          logo_path: "jakcx.png",
          duration: "February 2022 - PRESENT",
          location: "",
          description:
            "Monitoring ADA technician & sales, All in one reporting tools from infra and sales.",
          color: "#0879bf",
        },
        {
          title: "Indiscover",
          company: "Telkom Indonesia Project",
          company_url: "",
          logo_path: "indiscover.png",
          duration: "August 2019 - PRESENT",
          location: "",
          description:
            "Innovation project in HACK IDEA#4 as a solution to reach 100% valid database in Telkom Indonesia.",
          color: "#0879bf",
        },
        {
          title: "Posjar",
          company: "Telkom Indonesia Project",
          company_url: "",
          logo_path: "posjar.png",
          duration: "May 2020 - PRESENT",
          location: "",
          description:
            "Posjar coordinates activities between field patrols and the operations team, giving Telkom's maintenance teams the heads-up when an incident happens on the ground.",
          color: "#0879bf",
        },
        {
          title: "DIGISPBU",
          company: "Telkom Indonesia Project",
          company_url: "",
          logo_path: "digispbu.png",
          duration: "July 2019 - PRESENT",
          location: "",
          description:
            "Digital SPBU is a powerful app which created by Telkom Regional 2 to monitor activity progress of SPBU digitalization starting from survey until delivery service to customer.",
          color: "#0879bf",
        }
      ],
    }
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "dhiki.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours.",
  }
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  experience,
  contactPageData,
};
